<script>
import Table from "@/components/tables/table.vue";
import moment from "moment";

export default {
  name: "List",
  data() {
    return {
      search: '',
      items: [
        {
          text: "Home",
          href: "/",
        },
        {
          text: "Categories List",
          active: true,
        },
      ],
      table: {
        url: '/action_log/',
        searchable: true,
        headers: [
          {
            field: 'username',
            label: 'username',
            align: 'center'
          },
          {
            field: 'company',
            label: 'COMPANY',
            align: 'center',
          },
          {
            field: 'action',
            label: 'ACTION',
            align: 'center',
            searchable: false
          },
          {
            field: 'time',
            label: 'DATE',
            align: 'center',
            searchable: false
          }
        ]
      }
    }
  },
  components: {
    Table
  },
  methods: {
    formatDate(data) {
      if (!data) return '-'
      return moment(data).format('lll')
    },
    formatDateDifference(data) {
      if (!data) return '-'
      return moment(data).fromNow()
    }
  }
}
</script>

<template>
  <Table v-bind="table" class="mb-4">
    <template v-slot:username="{row: user}">
      <router-link v-if="user.slug" :to="{name: 'user_profile', params: {slug: user.slug}}">
        {{ user.username }}
      </router-link>
      <span v-else>
          {{ user.username }}
        </span>
    </template>
    <template v-slot:time="{row: data}">
      {{ formatDate(data.created_at) }} -
      ({{ formatDateDifference(data.created_at) }})
    </template>
  </Table>
</template>

<style scoped>

</style>