<script>
import PageHeader from "@/components/page-header.vue";
import LogsList from "@/views/pages/accounts/logs/List.vue";
import Statistics from "@/views/pages/accounts/logs/Statistics.vue";

export default {
  name: "List",
  data() {
    return {
      search: '',
      items: [
        {
          text: "Home",
          href: "/",
        },
        {
          text: "Categories List",
          active: true,
        },
      ],
    }
  },
  components: {
    PageHeader,
    LogsList,
    Statistics
  },
}
</script>

<template>
  <PageHeader title="Action Logs List" :items="items"/>

  <Statistics/>

  <LogsList/>
</template>

<style scoped>

</style>