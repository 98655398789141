<script>
import {
  CountTo
} from "vue3-count-to";
import axios from "axios";
import moment from "moment";

export default {
  name: "Statistics",
  components: {
    CountTo
  },
  data() {
    return {
      statistics: [],
      months: {
        1: 'January',
        2: 'February',
        3: 'March',
        4: 'April',
        5: 'May',
        6: 'June',
        7: 'July',
        8: 'August',
        9: 'September',
        10: 'October',
        11: 'November',
        12: 'December'
      },
      search: '',
      user_type: 'manager'
    }
  },
  computed: {
    filteredStatistics() {
      return this.statistics.filter(statistic => {
        return statistic.user.toLowerCase().includes(this.search.toLowerCase()) && (this.user_type ? statistic.user_type === this.user_type : true)
      })
    }
  },
  methods: {
    async getStatistics() {
      try {
        let response = await axios.get('/action_log/last_seen_statistic/')
        this.statistics = response.data
      } catch {
        alert("error while loading statistics")
      }
    },
    formatDate(data) {
      if (!data) return '-'
      return moment(data).format('lll')
    },
    formatDateDifference(data) {
      if (!data) return '-'
      return moment(data).fromNow()
    }
  },
  mounted() {
    this.getStatistics()
  }
}
</script>

<template>
  <b-row>
    <b-col sm="8">
      <input v-model="search" class="form-control mb-4" placeholder="Search for usernames...">
    </b-col>
    <b-col sm="4">
      <select v-model="user_type" class="form-select">
        <option value="">All users</option>
        <option value="admin">Admins</option>
        <option value="manager">Managers</option>
        <option value="client">Clients</option>
        <option value="moscow_manager">Moscow managers</option>
      </select>
    </b-col>
  </b-row>

  <b-row>
    <template v-for="statistic in filteredStatistics" :key="`statistic_${statistic.user}`">
      <b-col xl="3" md="6">
        <b-card no-body class="card-animate">
          <b-card-body>
            <div class="d-flex justify-content-between">
              <div>
                <div class="d-flex align-items-center gap-3">
                  <div class="avatar-xs flex-shrink-0">
                    <span class="avatar-title bg-soft-info text-info rounded-circle text-uppercase">
                      {{ statistic.user.charAt(0) }}
                    </span>
                  </div>
                  <h5 class="fw-medium mb-0">{{ statistic.user }}</h5>
                </div>
                <div class="d-flex gap-3 mb-3 pt-4">
                  <h5 class="ff-secondary fw-semibold mb-0" v-b-tooltip.hover title="Last login time">
                    {{ formatDate(statistic.last_activity) }}
                  </h5>
                  <span>
                    <b-badge class="bg-light text-info" v-b-tooltip.hover title="Last login time">
                      <i class="mdi mdi-clock-time-three-outline me-1"></i>
                      {{ formatDateDifference(statistic.last_activity) }}
                    </b-badge>
                  </span>
                </div>
                <p class="mb-0 text-muted fs-5">
                  <b-badge class="bg-light text-danger mb-0">
                    <count-to :startVal="0" :endVal="statistic.entries || 0" :duration="3000"></count-to>
                  </b-badge>
                  <span class="ms-1 fs-6">Entries in {{ months[new Date().getMonth() + 1] }}</span>
                </p>
              </div>
            </div>
          </b-card-body>

        </b-card>

      </b-col>
    </template>
  </b-row>
  <p v-if="filteredStatistics.length === 0">
    No statistics found
  </p>
</template>

<style scoped>

</style>